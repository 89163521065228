import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CormoraantPage = () => (
  <Layout>
    <SEO title="cormorant" />
    <h1>A man, a carving and a fish tale Fishing guide who hates cormorants says the likeness he created is for the birds</h1>
    <p>By CHRIS BROCK<br />
TIMES STAFF WRITER <br />
THURSDAY, JANUARY 6, 2011</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/cormorant1.jpg"
        alt="a man standing with a bird"
      />
    </div>
    <p>HENDERSON HARBOR — A double-crested cormorant has come home to roost for Ronald J. Ditch.</p>
    <p>To realize the significance of that, you must understand that Mr. Ditch does not like cormorants. He has lobbied
against them and in 1998 methodically killed scores of them on Little Galloo Island.</p>
    <p>He and nine other frustrated anglers killed nearly 2,000 of the federally protected voracious birds during trips to
the island to highlight how they were decimating supplies of sportsfish in Lake Ontario. Mr. Ditch and the others
were sentenced in 1999 to six months' home detention and up to two years' probation. They also were ordered to
pay a $2,500 fine and $5,000 in restitution.</p>
    <p>But late last month, a cormorant, life-size and carved out of basswood, was perched on Mr. Ditch's dining room
table, its mouth agape, as if surprised at landing in such hostile territory.</p>
    <p>Mr. Ditch also is surprised.</p>
    <p>“I don't know why I did it,” he said. “I expect I'll put it on the Internet and see if somebody would like to own it.”
</p>
    <p>Mr. Ditch, 75, owner of Ron Ditch and Sons Charter Service and Marina, finished his 55th season as a fishing
guide last year and recently returned to carving. He's the owner of Ontario Decoys, a business that took a hiatus
after his business partner, Paul B. Read, died in 2003 at 63.</p>
    <p>“When my partner died, I just kind of lost heart,” Mr. Ditch said. “Recently, I've gotten back into it somewhat.
There's a lot of work I've done in the past two years.”</p>
    <p>The wooden cormorant sculpture, which he started in 2009 at his winter home in Florida, is the biggest bird he's
carved. It stands 23 inches tall and has a 33-inch wingspan. In late December, all it needed was a paint job and
feet for completion.</p>
    <p>“I don't think I'll do another project as big as that damn cormorant,” he said between sips of coffee.
</p>
    <p>Mr. Ditch said he doesn't know how many hours he spent on the cormorant project, but one gets the idea that
maybe he doesn't want to know. He was asked if he gained a new appreciation for the bird species while working
on the carving, from the finely detailed feathers that were stained black from a carving tool to the bird's blue eyes
— an eye color that Mr. Ditch shares with cormorants.</p>
    <p>He spoke slowly and deliberately in many of his responses, ever the patient angler, fishing for the right word or
phrase and then setting the hook:</p>
    <p>“You know what?” he said. “There is truly not one thing that is complimentary about the bird.”</p>
    <p>He then gave a history lesson from the early 1900s. He said the British army was assigned to kill as many
cormorants as possible in the country because of what they were doing to fish stocks.</p>
    <p>He explained the double-crested cormorant is not native to the Great Lakes. “They came from the Mississippi
River into the Great Lakes from the west,” he said. “They just plain got way out of control.”
</p>
    <p>He said that when his cormorant is put online for auction, he'll seek a minimum bid of $7,500.
</p>
    <p>“Some people think the cormorant are just the greatest creatures that ever lived,” he said. “We'll now see how
wonderful they think the birds are. If somebody wants it bad enough to pay that kind of money for it, then they
really do love them.”</p>
    <p>Mr. Ditch has been fighting cormorants for years, and in the past few years he has seen success. Authorities
started paying attention to him and others about the damage the birds were causing to Lake Ontario's
sportsfishing industry.</p>
    <p>He looked out his large windows with a panoramic view of the harbor. “This is the greatest observatory in the world
right here,” he said.</p>
    <p>In the past few summers, he's been happy to see fewer cormorants. The birds are protected through the federal
Migratory Bird Protection Act of 1973. According to Wisconsin Sea Grant Institute, only cormorants in their
northernmost range, which includes Lake Ontario and the St. Lawrence River, migrate. Each fall they leave either
for the Pacific coast; along the Atlantic and Gulf coasts from North Carolina to the West Indies; or for inland sites
along large rivers and lakes north to Indiana.</p>
    <p>In 1999, the state Department of Environmental Conservation began to oil eggs on Little Galloo island, home to
one of the largest colonies of double-crested cormorants in the state. The corn oil clogs the tiny holes in the eggs,
killing the chicks inside. In 1999, there were more than 7,000 nesting pairs of cormorants on the island. As of June,
there were 1,750 nesting pairs. The DEC's goal is 1,468 nesting pairs.</p>
    <p>“Since they began to take control of the cormorants in this area, the fishing has been on the increase every year
for the past two or three years and I expect it to be exceptionally good this coming year,” Mr. Ditch said.</p>
    <p>He recalled the day in 1998 when he and some others took a boat to Little Galloo island and took cormorant
control matters into their own hands.</p>
    <p>“My livelihood depended on the fish out there,” he said. “After 14 years of fighting, something had to break
because I was getting the same promises and they wouldn't do anything about it. It had to happen.”</p>
    <p>He said he's amazed at the number of thank-you letters he received for his cormorant spree.
</p>
    <p>“They thanked me for taking the action that we did at the time to get some results, because a lot of people
maintain that it saved the fishery in this area of the lake.”</p>
    <p>Mr. Ditch calls cormorants “fish-eating machines.”</p>
    <p>“They are so adapted to swimming, they can outswim a fish,” he said. “They have real long endurance. I wouldn't
be surprised if they can dive to 80 to 100 feet.”</p>
    <p>He said a cormorant can eat up to a pound-and-a-half of fish a day.
</p>
    <p>“One time, I figured there were 150,000 of those birds in the general area between here and the St. Lawrence
River,” Mr. Ditch said. “You figure that out on a per-day basis and on a seven-month basis the birds are here, and
it's no wonder our fishing went to hell back then.”</p>
<p>But Mr. Ditch looks to the future of sportsfishing on the lake with optimism and has no plans of slowing down as a
guide. He notes charter captains must take a test every five years from the U.S. Coast Guard. He plans to take the
test next year, and be certified for the next five years, and at the certification's conclusion he'll turn 80.</p>
<p>“I fully intend to do one more term minimum,” he said. “I have always made a major part of my life hunting, fishing
and carving.”</p>
<p>Many of his clients are repeat customers. “The only reason I don't have 100 percent repeat customers is because
every year a few of them either die, move away or take up golf.”</p>
<p>He recalled getting into carving while a student at Henderson High School because he couldn't afford to purchase
decoys. A carver in the village told him to go to Pierrepont Manor, where they were disassembling old railroad box
cars. The cars were lined with cork, which make excellent decoy material.</p>
<p>“They worked so well, people wanted to buy them” he said. “I know several people who have them. They won't use
them. They set them aside for collectors' items. They're increased substantially in value.”</p>
<p>Mr. Ditch said it feels good to be back in the carving business, but he'll do items only on a commission basis. If the
basswood cormorant is sold, however, and he is inspired in other unexpected ways, he'll be ready.</p>
<p>“I understand basswood is going to be in short supply,” he said. “The trees are starting to get some disease. But I
have a lifetime supply of wood cut.”</p>
<p>THE DETAILS< br/>
WHAT: Double-crested cormorant.< br/>
NAME ORIGIN: Latin for "sea crow." They grow white plumes on the head during breeding season.< br/>
WEIGHT: 3.3 to 6.6 pounds< br/>
SIZE: 33 inches in length, with a wingspan of about 4 feet< br/>
LIFESPAN: Up to 20 years.< br/>
OFFSPRING: Average two to four young a year< br/>
FEEDING BEHAVIOR: Dives from the surface of the water and chases prey. Grabs fish, doesn't spear them.< br/>
STOMACH ANALYSIS: Biggest fish found in the stomach of a dissected double-crested cormorant at Lake Ontario
was a 201/4-inch lake trout.
Sources: National Audubon Society, allaboutbirds.com, veteran Lake Ontario fishing guide Ronald J. Ditch< br/>
SHOW COMMENTS (0)PHOTOS< br/>
Veteran fishing guide and carver Ronald J. Ditch poses at his studio with his latest art project, a double-crested
cormorant.< br/>
JUSTIN SORENSEN / WATERTOWN DAILY TIMES</p>
<p className="text-semibold">Note:&nbsp;The carving is currently on loan to the Henderson Historical Society and is displayed at their
museum in Henderson, NY</p>
<a href="https://www.nny360.com/article/20110106/curr04/301069997/-1/curr/">Click Here for Pictures and the complete article</a>

  </Layout>
)

export default CormoraantPage
